import { Button } from "@mui/material";

export default function Volunteer() {
  return (
    <>
      <div className="fadein">
        <div className="volunteer-text">
          <div className="tutorswithimage">
            <div>
              <div className="top-tutors">
                <div className="top-tutor-text">
                  <b>The Top Tutors</b>
                </div>
                <div className="text">
                  At Limix Org, we strive to have the most suited and talented
                  tutors, aiming to achieve elite education for our students.
                  Our tutor application forms are thoroughly read, and each
                  applicant is evaluated and interviewed by us, in order to
                  ensure the best tutoring experience possible. They will
                  undergo in a mock session of tutoring with a member from our
                  program, where we can assess our tutor's strengths and
                  weaknesses, allowing us to optimize their teaching skills for
                  maximum efficiency. Additionally, each tutor's
                  extracurriculars and academic background will be listed, so
                  our clients can find themselves familiar with people of the
                  same interests.
                </div>
              </div>
              <div className="top-tutors">
                <div className="top-tutor-text">
                  <b>Want to become a tutor?</b>
                </div>
                <div className="text">
                  Becoming a tutor at Limix Org is an extremely honorable role.
                  Tutoring young students will not only grant you a lot of
                  volunteering hours, but also skills to becoming a better
                  learner and teacher. If you would like to sign up, please
                  press the button below:
                </div>
              </div>
              <Button
                sx={{ textTransform: "none" }}
                className="become-A-Tutor"
                size="large"
                style={{
                  fontSize: "2vw",
                  maxWidth: "22vw",
                  maxHeight: "9vw",
                  minWidth: "13vw",
                  minHeight: "4.5vw",
                  backgroundColor: "#fbda73",
                  marginTop: "4%",
                  marginBottom: "8%",
                }}
                href="https://forms.gle/a5EEXejRGFXxwcQH9"
                rel="noreferrer"
                target="_blank"
                color="inherit"
                variant="contained"
              >
                <div style={{ fontFamily: "'Outfit'" }}>
                  <b>Sign Up</b>
                </div>
              </Button>
            </div>
            <img
              src="/images/schools.webp"
              alt="Schools"
              className="schools-image"
              style={{
                backgroundSize: "cover",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "30%",
                objectFit: "contain",
                maxHeight: "55vb",
                width: "auto",
              }}
            ></img>
          </div>
        </div>
      </div>
    </>
  );
}
