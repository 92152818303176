import { useState } from "react";

import { Autocomplete, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function Tutoring({ setDisabled }) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);

  const handleAutoChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  const onClick = () => {
    setDisabled(true);
    setLoading(true);
    if (selectedValue === null) {
      setDisabled(false);
      setLoading(false);
      return;
    }
    if (sent) {
      setDisabled(false);
      setLoading(false);
      return;
    }

    var formattedData = {
      course: selectedValue,
    };

    formattedData = JSON.stringify(formattedData);

    fetch("/api/tutoring/gettutored", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: formattedData,
    })
      .then((response) => {
        setLoading(false);
        setDisabled(false);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setLoading(false);
        setDisabled(false);
        if (data && data.redirect) {
          window.top.location.href = data.redirect;
        }
        if (data && data.message) {
          setMessage(data.message);
          setSent(true);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        setDisabled(false);
        console.error(err);
        setErr(true);
        setMessage("There was an error");
      });
  };

  return (
    <>
      <div className="fadein">
        <div className="tutoring">
          <div className="reqTutoring">Request Tutoring Session</div>
          <div className="tutoringstatus">
            Select a course you want to be tutored in:
          </div>
          <div className="reqButtonContainer">
            <div className="auto-container">
              <Autocomplete
                disablePortal
                id="Courses"
                options={Courses}
                className="courses-auto"
                onChange={handleAutoChange}
                disabled={loading}
                renderInput={(params) => (
                  <TextField
                    className="courses-text"
                    {...params}
                    label="Courses"
                  />
                )}
              />
            </div>
            <div className="buttonauto-container">
              <LoadingButton
                className="courses-button"
                variant="contained"
                loading={loading}
                size="large"
                color="success"
                onClick={onClick}
                disabled={sent ? sent : selectedValue == null ? true : false}
              >
                <div className="course-button-text">
                  Request Tutoring Session
                </div>
              </LoadingButton>
            </div>
          </div>
          <div className="messageText" style={{ color: err ? "red" : "black" }}>
            {message}
          </div>

          <div className="courseDescription">Course Descriptions</div>
          <div className="prerequisites">
            We tutor most students online through Zoom. Students who live in
            Palos Verdes, CA or Torrance, CA can order in-person tutoring for an
            extra fee. If you are having problems with Zoom, we can also teach
            through Microsoft Teams.
          </div>
          <div className="prerequisites">
            Note: In order to be tutored, you must be currently enrolled in the
            current course or enrolled in a similar class
          </div>
          <div className="prerequisites">
            If (H) or (AP) is displayed next to the course, honors and AP class
            tutoring is offered
          </div>
          <div className="classes">
            <div className="subjectSect">Mathematics</div>
            <div className="subject">
              <div className="class">
                <div className="classtitle">Common Core Math</div>
                <div className="description">
                  Common Core Math lays the mathmatical foundation that is
                  needed in order to understand higher level algebraic math
                  concepts. Common Core Math covers basic mathmatical operations
                  and their appications to more complex math topics.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Pre-Algebra</div>
                <div className="description">
                  Pre-Algebra introduces fundamental mathematical concepts,
                  including arithmetic, fractions, decimals, and basic algebraic
                  expressions, laying a strong foundation for higher-level math
                  studies.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Algebra 1</div>
                <div className="description">
                  Algebra 1 equips students with the tools to solve equations,
                  work with variables, and understand linear functions, building
                  essential problem-solving skills for real-world applications.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Geometry (H)</div>
                <div className="description">
                  Geometry explores the properties of shapes, lines, and angles,
                  enabling students to understand spatial relationships and
                  develop geometric reasoning crucial for various fields such as
                  engineering and architecture.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Algebra 2 (H)</div>
                <div className="description">
                  Algebra 2 delves deeper into advanced algebraic concepts,
                  including polynomials, logarithms, and exponential functions,
                  fostering a deeper understanding of mathematical patterns and
                  relationships.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Pre-Calculus (H) (AP)</div>
                <div className="description">
                  Pre-Calculus prepares students for advanced calculus studies
                  by focusing on functions, trigonometry, and mathematical
                  modeling, laying a solid groundwork for higher mathematical
                  analysis and problem-solving.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Calculus</div>
                <div className="description">
                  Calculus is an entry-level course that introduces students to
                  the basics of calculus, focusing on concepts like rates of
                  change and accumulation. You'll learn how to find derivatives
                  and integrals, laying the groundwork for more advanced math
                  and science studies.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">AP Calculus AB</div>
                <div className="description">
                  Calculus AB is a math class that explores the beginning
                  concepts of calculus. It covers slopes, areas under curves,
                  and problem-solving using these concepts. It's popular for
                  those interested in math, science, or engineering.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Statistics (AP)</div>
                <div className="description">
                  Statistics provides a comprehensive understanding of data
                  analysis, probability, and inferential reasoning, equipping
                  students with the skills to interpret and draw meaningful
                  conclusions from data sets in various contexts.
                </div>
              </div>
            </div>
            <div className="subjectSect">Sciences</div>
            <div className="subject">
              <div className="class">
                <div className="classtitle">Biology (H) (AP)</div>
                <div className="description">
                  Biology explores the study of living organisms, their
                  structures, functions, and evolutionary processes, providing a
                  comprehensive understanding of the natural world and the
                  interconnectedness of life forms.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Chemistry (H) (AP)</div>
                <div className="description">
                  Chemistry delves into the study of matter, its properties,
                  composition, and interactions, providing a foundational
                  understanding of chemical processes and their applications in
                  various fields, including medicine and industry.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Physics</div>
                <div className="description">
                  Physics investigates the fundamental principles governing the
                  behavior of matter and energy, exploring concepts such as
                  motion, forces, and the laws of thermodynamics, enabling a
                  deeper understanding of the natural world.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">AP Physics 1</div>
                <div className="description">
                  AP Physics 1 provides a comprehensive study of foundational
                  physics concepts, including kinematics, dynamics, and
                  fundamental principles of energy, encouraging critical
                  thinking and problem-solving skills in the field of physics.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">AP Physics 2</div>
                <div className="description">
                  AP Physics 2 explores advanced topics in physics, including
                  fluid mechanics, thermodynamics, and electromagnetism,
                  providing a deeper understanding of the principles governing
                  the behavior of the natural world at a more complex level.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">AP Physics C</div>
                <div className="description">
                  AP Physics C focuses on advanced topics in physics,
                  emphasizing mechanics and electricity, providing students with
                  a deeper understanding of calculus-based physics and its
                  practical applications in various scientific fields.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">AP Computer Science Principles</div>
                <div className="description">
                  AP Computer Science Principles offers an introduction to
                  computer science, covering foundational concepts, algorithms,
                  and the societal impacts of computing technologies, fostering
                  critical thinking and problem-solving skills in a digital age.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">AP Computer Science A</div>
                <div className="description">
                  AP Computer Science A focuses on advanced programming
                  concepts, data structures, and the design of computer-based
                  solutions, equipping students with the skills to develop and
                  implement complex programs and applications.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">Specialized Computer Sciences</div>
                <div className="description">
                  Specialized Computer Sciences focuses on specific coding
                  languages like Advanced Java, Python, C++, or React. It helps
                  students become experts in these languages, teaching them how
                  to create advanced software, applications, and algorithms.
                  Through hands-on projects and practical exercises, students
                  will learn the ins and outs of their chosen language,
                  preparing them for careers in software engineering, web
                  development, or other tech fields.
                </div>
              </div>
            </div>
            <div className="subjectSect">Social Studies</div>
            <div className="subject">
              <div className="class">
                <div className="classtitle">World History (H) (AP)</div>
                <div className="description">
                  World History provides an in-depth exploration of global
                  civilizations, cultures, and major historical events,
                  fostering a broader understanding of the interconnectedness of
                  human societies throughout different time periods.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">European History (H) (AP)</div>
                <div className="description">
                  European History delves into the rich cultural heritage and
                  political developments of Europe, examining the evolution of
                  societies, governments, and cultural movements that have
                  shaped the continent's contemporary landscape.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">US History (H) (AP)</div>
                <div className="description">
                  US History examines the political, economic, and social
                  evolution of the United States, focusing on key events,
                  influential figures, and the country's enduring impact on the
                  global stage.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Economics (AP)</div>
                <div className="description">
                  Economics introduces students to the fundamental principles of
                  microeconomics and macroeconomics, emphasizing the study of
                  resource allocation, market dynamics, and the impact of
                  economic decisions on society.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Government (AP)</div>
                <div className="description">
                  Government provides an understanding of political
                  institutions, processes, and the roles of citizens, fostering
                  an awareness of the rights, responsibilities, and functions of
                  government within a democratic society.
                </div>
              </div>
            </div>
            <div className="subjectSect">Social Sciences</div>
            <div className="subject">
              <div className="class">
                <div className="classtitle">AP Psychology</div>
                <div className="description">
                  AP Psychology helps high school students understand how people
                  think and behave. We will cover topics like why we feel
                  certain emotions, how our brains work, and why people act
                  differently. We will focus on different theories to learn more
                  about the mind. It's a great class for anyone curious about
                  human behavior and thinking.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">Ethics and Philosophy</div>
                <div className="description">
                  Ethics and Philosophy examines moral principles and critical
                  thinking. Students explore concepts like fairness and justice
                  through philosophical ideas and real-world examples. It helps
                  students develop ethical reasoning skills and a thoughtful
                  approach to complex issues.
                </div>
              </div>
            </div>
            <div className="subjectSect">Languages</div>
            <div className="subject">
              <div className="class">
                <div className="classtitle">Spanish (H) (AP)</div>
                <div className="description">
                  Spanish provides comprehensive language instruction, focusing
                  on vocabulary, grammar, and conversational skills, fostering
                  proficiency in both written and spoken Spanish. Students will
                  explore the rich cultural heritage of Spanish-speaking
                  countries, enhancing their ability to communicate and engage
                  in various Spanish-speaking environments.
                </div>
              </div>

              <div className="class">
                <div className="classtitle">Chinese (H) (AP)</div>
                <div className="description">
                  Chinese offers a comprehensive study of the Chinese language,
                  emphasizing character recognition, pronunciation, and cultural
                  understanding. Students will develop proficiency in reading,
                  writing, and speaking Mandarin, gaining insights into Chinese
                  culture and traditions while building effective communication
                  skills in the language.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">Japanese (H)</div>
                <div className="description">
                  Japanese offers a comprehensive study of the Japanese language
                  and culture, focusing on grammar, vocabulary, and
                  communication skills, fostering proficiency in speaking,
                  reading, and understanding the Japanese language within its
                  cultural context.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">Korean (H)</div>
                <div className="description">
                  Korean provides comprehensive language instruction in the
                  Korean language, emphasizing conversational fluency, writing,
                  and cultural understanding, enabling effective communication
                  in spoken and written Korean.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">French (H)</div>
                <div className="description">
                  French provides a comprehensive exploration of the French
                  language, focusing on grammar, vocabulary, and communication
                  skills. Students will delve into French literature, art, and
                  history, gaining a deeper understanding of French culture and
                  society while developing the ability to communicate fluently
                  in spoken and written French.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">Italian (H)</div>
                <div className="description">
                  Italian provides an immersive introduction to Italian grammar,
                  vocabulary, and conversational skills, while exploring the
                  essence of Italian life through art, and history. This course
                  will develop an understanding for the Italian language while
                  developing the ability to communicate fluently in spoken and
                  written Italian.
                </div>
              </div>
            </div>
            <div className="subjectSect">English</div>
            <div className="subject">
              <div className="class">
                <div className="classtitle">Literature</div>
                <div className="description">
                  Literature explores a wide range of literary works, including
                  novels, poems, and plays, fostering critical analysis,
                  interpretation, and appreciation of various literary genres,
                  styles, and themes, nurturing a deep understanding and love
                  for literature.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">Essay Writing</div>
                <div className="description">
                  Essay Writing focuses on developing effective writing skills,
                  including structuring essays, crafting persuasive arguments,
                  and expressing ideas coherently, preparing students to
                  communicate effectively and convincingly through written
                  compositions.
                </div>
              </div>
              <div className="class">
                <div className="classtitle">Vocabulary Development</div>
                <div className="description">
                  Vocabulary Development emphasizes the acquisition and
                  application of a diverse range of words, enhancing language
                  proficiency, comprehension, and effective communication across
                  various disciplines and contexts, nurturing a strong command
                  of language and expression.
                </div>
              </div>
            </div>
          </div>
          <div className="Pricing">Pricing</div>
          <div className="priceDesc">
            <b>$45/Session</b> when you buy a single 1 hour session
          </div>
          <div className="priceDesc">
            <b>$40/Session</b> when you buy three or more 1 hour sessions
          </div>
          <div className="priceDesc">
            <b>$5</b> fee for requested in-person tutoring offered to Palos
            Verdes and Torrance students
          </div>
          <div className="payment">
            To pay for your tutoring session, we will email you the payment
            information. We accept most virtual payment methods that include
            Paypal, Venmo, Zelle, and CashApp.
          </div>
          <div className="payment">
            After requesting a class, we will create a suitable schedule with
            you over email based off available tutoring hours.
          </div>
          <div className="payment">A user portal will be coming soon!</div>
        </div>
      </div>
    </>
  );
}

const Courses = [
  { label: "Common Core Math" },
  { label: "Pre-Algebra" },
  { label: "Algebra 1" },
  { label: "Geometry" },
  { label: "Geometry H" },
  { label: "Algebra 2" },
  { label: "Algebra 2 H" },
  { label: "Pre-Calculus" },
  { label: "Pre-Calculus H" },
  { label: "Pre-Calculus AP" },
  { label: "Calculus" },
  { label: "AP Calculus AB" },
  { label: "Statistics" },
  { label: "Statistics AP" },
  { label: "Biology" },
  { label: "Biology H" },
  { label: "Biology AP" },
  { label: "Chemistry" },
  { label: "Chemistry H" },
  { label: "Chemistry AP" },
  { label: "Physics" },
  { label: "Physics 1 AP" },
  { label: "Physics 2 AP" },
  { label: "AP Physics C" },
  { label: "AP Computer Science Principles" },
  { label: "AP Computer Science A" },
  { label: "Specialized Coding (Advanced Java, C++, Python, React)" },
  { label: "World History" },
  { label: "World History H" },
  { label: "World History AP" },
  { label: "European History" },
  { label: "European History H" },
  { label: "European History AP" },
  { label: "US History" },
  { label: "US History H" },
  { label: "US History AP" },
  { label: "Economics" },
  { label: "Economics AP" },
  { label: "Government" },
  { label: "Government AP" },
  { label: "AP Psychology" },
  { label: "Ethics and Philosophy" },
  { label: "Spanish" },
  { label: "Spanish H" },
  { label: "Spanish AP" },
  { label: "Chinese" },
  { label: "Chinese H" },
  { label: "Chinese AP" },
  { label: "Japanese" },
  { label: "Japanese H" },
  { label: "Korean" },
  { label: "Korean H" },
  { label: "French" },
  { label: "French H" },
  { label: "Italian" },
  { label: "Italian H" },
  { label: "Reading Comprehension" },
  { label: "Essay Writing" },
  { label: "Vocabulary Development" },
];

export default Tutoring;
