import React from "react";
import "./styles.css";

export default function FAQ({ changeView }) {
  const handleClick = () => {
    // Define the function you want to run when the text is clicked.
    changeView("volunteer");
  };

  return (
    <div className="fadein">
      <div className="faq-container">
        <div className="faq-heading">Frequently Asked Questions</div>

        <div className="faq-question">What is Limix Organization?</div>
        <div className="faq-answer">
          Limix Organization is a non-profit tutoring organization dedicated to
          providing educational support to students from all academic
          backgrounds. Our mission is to bridge the educational gap and develop
          a love for learning by offering high-quality tutoring services and
          academic guidance.
        </div>

        <div className="faq-question">
          What services does Limix Organization provide?
        </div>
        <div className="faq-answer">
          We offer a range of tutoring services in a variety of subjects,
          including but not limited to mathematics, science, english, history,
          and languages. Our experienced tutors are committed to providing
          personalized attention and support to help students reach their
          academic goals.
        </div>

        <div className="faq-question">
          Who can benefit from Limix Organization's services?
        </div>
        <div className="faq-answer">
          Our services are designed to benefit students of all ages and academic
          levels. Whether you are a high school student trying to raise your
          grade point average (GPA), a college student needing assistance with
          coursework, or a middle schooler interested in future advanced
          classes, we are here to help you succeed.
        </div>

        <div className="faq-question">
          How can I access Limix Organization's tutoring services?
        </div>
        <div className="faq-answer">
          You can easily access our tutoring services by requesting a class
          through our tutoring page. You can also contact us via email about
          information for specific subjects through our website. Our team will
          get in touch with you as soon as possible and match you with a
          suitable tutor.
        </div>

        <div className="faq-question">
          How can I support Limix Organization's mission?
        </div>
        <div className="faq-answer">
          You can support research for children's cancer by donating to
          Children's Cancer Therapy Development Institute (cc-TDI). You can
          visit their website{" "}
          <a
            rel="noreferrer"
            href="https://cc-tdi.org/"
            target="_blank"
            style={{
              "font-family": "Outfit",
              color: "black",
              "font-size": "inherit",
              textDecoration: "underline",
              "text-decoration-color": "black",
              cursor: "pointer",
              background: "none",
              border: "none",
            }}
          >
            here
          </a>
          . You can also learn more about children's cancer{" "}
          <a
            rel="noreferrer"
            href="https://www.ncbi.nlm.nih.gov/books/NBK507721/"
            target="_blank"
            style={{
              "font-family": "Outfit",
              color: "black",
              "font-size": "inherit",
              textDecoration: "underline",
              "text-decoration-color": "black",
              cursor: "pointer",
              background: "none",
              border: "none",
            }}
          >
            here
          </a>
          . We hope to contribute towards research and awareness by donating
          resources towards the cause. Help us spread awareness for children's
          cancer by following our social medias.
        </div>

        <div className="faq-question">
          Is Limix Organization affiliated with any other educational
          institutions?
        </div>
        <div className="faq-answer">
          Limix Organization operates independently as a non-profit entity.
          Although we do not collaborate with other educational institutions at
          this moment in time, we are still open to any sort of collaboration in
          the near future.
        </div>

        <div className="faq-question">
          How can I get involved with Limix Organization as a volunteer?
        </div>
        <div className="faq-answer">
          If you are passionate about education and want to make a difference in
          our society, we encourage you to join our team of dedicated tutors.
          You can contribute by becoming a tutor and helping others achieve
          their academic goals.{" "}
          <h
            onClick={handleClick}
            style={{
              "font-family": "Outfit",
              "font-size": "inherit",
              textDecoration: "underline",
              cursor: "pointer",
              background: "none",
              border: "none",
            }}
          >
            Click here
          </h>{" "}
          to learn more about becoming a volunteer! For any further inquiries or
          assistance, please don't hesitate to contact us directly through our
          email (JoshRoe@limixapp.org).
        </div>

        <div className="faq-answer">
          For any further inquiries or assistance, please don't hesitate to
          contact us directly through our website or email.
        </div>

        <div className="faq-answer">
          Thank you for your interest in Limix Organization!
        </div>
      </div>
    </div>
  );
}
