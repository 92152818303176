import { React, useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { Button } from "@mui/material";
import { Settings } from "@mui/icons-material";

function NavBar({ changeView, disabled }) {
  const [buttonText, setButtonText] = useState("");
  const [loggedin, setLoggedin] = useState(false);

  const [width] = useState(window.innerWidth);

  useEffect(() => {
    fetch("/api/login/isloggedin", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.loggedin === true){
          setButtonText(<Settings className="settings-button"  sx={{ fontSize: "inherit", color: "black"}} />)
          setLoggedin(true);
          return;
        }
        setButtonText(<div>LOG&nbsp;IN</div>)
        setLoggedin(false);
      })
      .catch((error) => {
        setButtonText(<div>LOG&nbsp;IN</div>)
        setLoggedin(false);
        console.error("Fetch error:", error);
      });
  }, []);

  const [translateY, setTranslateY] = useState(0);

  useEffect(() => {
      const handleScroll = () => {
          const endMove = window.innerHeight * 0.35;

          if (window.scrollY > endMove) {
              setTranslateY(-100);
          } else {
              setTranslateY(0);
          }
      };

      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      className={`nav-bar`}
      style={{ transform: `translateY(${translateY}%)` }}
    >
      <IconButton
        onClick={() => {
          changeView("home");
        }}
        color="primary" // Change to your preferred color
        aria-label="Image Button"
        className="nav-bar-button-img"
        disabled={disabled}
      >
        <img
          src="/images/Logo.webp" // Replace with your image path
          alt="Logo"
          className="nav-bar-img"
        />
      </IconButton>
      <div className="nav-bar-button-container">
        <Button
          sx={{ minWidth: 0 }}
          color="inherit"
          size="small"
          className="nav-bar-button o"
          onClick={() => {
            changeView("home");
          }}
          disabled={disabled}
        >
          <div className="nav-bar-text">
            <b>Home</b>
          </div>
        </Button>
        <Button
          sx={{ minWidth: 0 }}
          color="inherit"
          size="small"
          className="nav-bar-button t"
          onClick={() => {
            changeView("volunteer");
          }}
          disabled={disabled}
        >
          <div className="nav-bar-text">
            <b>Volunteer</b>
          </div>
        </Button>
        <Button
          sx={{ minWidth: 0 }}
          color="inherit"
          size="small"
          className="nav-bar-button th"
          onClick={() => {
            changeView("tutoring");
          }}
          disabled={disabled}
        >
          <div className="nav-bar-text">
            <b>Tutoring</b>
          </div>
        </Button>
        <Button
          sx={{ minWidth: 0 }}
          color="inherit"
          size="small"
          className="nav-bar-button f"
          onClick={() => {
            changeView("help");
          }}
          disabled={disabled}
        >
          <div className="nav-bar-text">
            <b>Directory</b>
          </div>
        </Button>
        <Button
          sx={{ minWidth: 0 }}
          color="inherit"
          size="small"
          className="nav-bar-button fi"
          onClick={() => {
            changeView("faq");
          }}
          disabled={disabled}
        >
          <div className="nav-bar-text">
            <b>FAQ</b>
          </div>
        </Button>
        <Button
          sx={{
            minWidth: 0,
            backgroundColor: loggedin ? "transparent" : "#51b1e0",
            '&:hover': {
              backgroundColor: loggedin ? "transparent" : "#75b1e0", 
            }
          }}
          color={loggedin ? "inherit" : "inherit"}
          size={width < 728 ? "small" : "large"}
          variant={loggedin ? "text" : "contained"}
          className="log-bar-button"
          href={loggedin ? "/settings?back=home" : "/login"}
          disabled={disabled}
        >
          <div className="log-in-button">
            <b>{buttonText}</b>
          </div>
        </Button>
      </div>
    </div>
  );
}

export default NavBar;
