import {useEffect} from 'react';

function About() {
  return (
    <>
      <div style={{ maxWidth: "100%" }} className="fadein">
        <div className="about">
          <div className="about-photo-container">
            <img
              src="/images/HomeBackground.webp"
              alt="Logo"
              className="about-photo"
            />
            <div className="image-text">
              <div>lim</div>
            </div>
          </div>
          <div className="bottom-part">
            <div className="what-is">
              <b className="what-is-title">What is the Limix Organization?</b>
              <br />
              <div className="what-is-text">
                The Limix Organization is a non-profit online and in-person
                tutoring organization that donates funds to the Children’s
                Cancer Therapy Development Institute (
                <a
                  rel="noreferrer"
                  href="https://cc-tdi.org/"
                  target="_blank"
                  style={{
                    fontFamily: "Outfit",
                    color: "black",
                    fontSize: "inherit",
                    textDecoration: "underline",
                    textDecorationColor: "black",
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                  }}
                >
                  cc-TDI
                </a>
                ). Our mission is to help students improve academically while
                supporting cutting edge research on childhood cancer.
              </div>
            </div>
            <div className="guy-img">
              <img
                src="/images/guy-Home.webp"
                alt="guy"
                className="image-guy"
              />
            </div>
          </div>
          <div className="bottom-part two">
            <div className="bottom-part-images">
              <div className="ribbon-img">
                <img
                  src="/images/ribbon.webp"
                  alt="golden ribbon"
                  className="image-ribbon"
                />
              </div>
              <div className="cctdi-img">
                <img
                  src="/images/cctdi.webp"
                  alt="golden ribbon"
                  className="image-cctdi"
                />
              </div>
            </div>
            <div className="what-is two">
              <b className="what-is-title">
                Why support research on childhood cancer?
              </b>
              <br />
              <div className="what-is-text two">
                Childhood cancer, often overlooked by major pharmaceutical and
                biotechnology companies, is a field that deserves our attention
                and investment. Despite more than 15,000 estimated cases of
                childhood cancer in the US each year (
                <a
                  rel="noreferrer"
                  href="https://www.cancer.gov/types/childhood-cancers/child-adolescent-cancers-fact-sheet"
                  target="_blank"
                  style={{
                    fontFamily: "Outfit",
                    color: "black",
                    fontSize: "inherit",
                    textDecoration: "underline",
                    textDecorationColor: "black",
                    cursor: "pointer",
                    background: "none",
                    border: "none",
                  }}
                >
                  cancer.gov
                </a>
                ), there's a tremendous opportunity to advance our understanding
                and treatment for some of the most underserved childhood
                cancers. By providing support, you empower dedicated scientists
                at cc-TDI to explore critical avenues for childhood cancer
                research, investing in a pipeline to bring safe therapies to
                clinical trials.
              </div>
            </div>
          </div>
          {/*<div className="homeInsta">
            <div className="our-insta">Our Instagram</div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <InstagramEmbed url="https://www.instagram.com/p/C1LoFIiLm1B/" />
            </div>
                </div>*/}
        </div>
      </div>
    </>
  );
}

/*const InstagramEmbed = ({ url }) => {
  useEffect(() => {
    const loadInstagramScript = () => {
      const script = document.createElement('script');
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        if (window.instgrm) {
          window.instgrm.Embeds.process();
        }
      };
    };

    loadInstagramScript();
  }, [url]);

  return (
    <blockquote
      className="instagram-media"
      data-instgrm-captioned
      data-instgrm-permalink={url}
      data-instgrm-version="14"
      style={{
        background: '#FFF',
        border: '0',
        borderRadius: '3px',
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
        margin: '1px',
        maxWidth: '540px',
        minWidth: '326px',
        padding: '0',
        width: '99.375%',
      }}
    >
      <div style={{padding: '16px'}}>
      </div>
    </blockquote>
  );
};
*/
export default About;
