import InstagramIcon from "@mui/icons-material/Instagram";
import { IconButton } from "@mui/material";

export default function footer() {
  const view = window.innerWidth;

  var text = "";

  if (view > 768) {
    text = "large";
  }

  return (
    <>
      <div className="fadein">
        <div className="footer">
          <div className="logo-footer">
            <img
              src="/images/white-logo.webp" // Replace with your image path
              alt="Logo"
              className="footer-img"
            />
          </div>
          <div className="content-footer">
            <div className="footer-text">
              @Limix Org 2023. All rights reserved •&nbsp;
              <a style={{ color: "white" }} href="/terms">
                Terms of Use
              </a>
              <IconButton
                className="insta"
                style={{ color: "white" }}
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/limixorg_pv/"
              >
                <InstagramIcon fontSize={text} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
