import NavBar from "./NavBar";
import Footer from "./Footer"
import About from "./About";
import Volunteer from "./Volunteer";
import FAQ from "./FAQ";
import Tutoring from "./Tutoring";
import Directory from "./Directory";
import { useState } from "react";

export default function Home() {
  const [about, setAbout] = useState(true);
  const [volunteer, setVolunteer] = useState(false);
  const [tutoring, setTutoring] = useState(false);
  const [help, setHelp] = useState(false);
  const [faq, setFaq] = useState(false);

  const [disabled, setDisabled] = useState(false);

  function setAllFalse(){
    setAbout(false);
    setVolunteer(false);
    setTutoring(false);
    setHelp(false);
    setFaq(false);
  }

  function changeView(view) {
    if (view === "home") {
      if(about !== true){
        setAllFalse();
        setAbout(true);
      }
    }
    else if (view === "volunteer") {
      if(volunteer !== true){
        setAllFalse();
        setVolunteer(true);
      }
    }
    else if (view === "tutoring") {
      if(tutoring !== true){
        setAllFalse();
        setTutoring(true);
      }
    }
    else if (view === "help") {
      if(help !== true){
        setAllFalse();
        setHelp(true);
      }
    } 
    else if (view === "faq") {
      if(faq !== true){
        setAllFalse();
        setFaq(true);
      }
    } else {
      if(about !== true){
        setAllFalse();
        setAbout(true);
      }
    }
  }

  function setD(dis){
    setDisabled(dis);
  }

  return (
    <>
      <NavBar changeView={changeView} disabled={disabled} className="nav-bar" />
      <>
      {
      about ? <About/> 
      : volunteer ? <Volunteer/> 
      : tutoring ? <Tutoring setDisabled={setD}/>
      : help ? <Directory className={'fadein active'}/>
      : faq ? <FAQ changeView={ changeView } /> 
      : <About/> 
      }
      </>
      <Footer />
    </>
  );
}
