function Directory() {
  return (
    <>
      <div className="fadein">
        <div className="directoryPg">
          <div className="TheTeam">The Team</div>
          <div className="groupPhoto-container" style={{ display: "none" }}>
            <img
              src="/images/GroupPhoto.webp"
              alt="LimixFounders"
              className="group-photo"
            />
          </div>

          <div className="tutordirectory">
            <div className="OurTutors">Our Tutors</div>
            <div
              className="groupPhoto-text"
              style={{ "marginTop": "-4%", "marginBottom": "3%" }}
            >
              The Limix Organization Owners are: Josh Roe, Brett Moody, and
              Leroy Yang
              <br />
              The Limix Organization Contributors: Connor Lee, Abigail Chiang,
              Victoria Baik, Lucia Jeong, and Tubaki Miyahara
            </div>
            <div className="tutors-grid">
              <Person
                ImageName="JoshRoe"
                Name="Josh Roe"
                Subjects="Geometry (H), Algebra 2 (H), Pre-Calculus (H) (AP), Calculus, AP Calculus AB, Chemistry (H) (AP), Physics, AP Physics 1, AP Physics 2, AP Physics C, AP Computer Science A"
                Email="JoshRoe"
                TITLE="CO-FOUNDER"
              />
              <Person
                ImageName="BrettMoody"
                Name="Brett Moody"
                Subjects="Pre-Algebra, Algebra 1, Geometry (H), AP Computer Science Principals, AP Computer Science A, Specialized Computer Sciences"
                Email="BrettMoody"
                TITLE="CO-FOUNDER"
              />
              <Person
                ImageName="LeroyYang"
                Name="Leroy Yang"
                Subjects="Common Core Math, Pre-Algebra, Algebra 1, Geometry (H), Algebra 2 (H), Chemistry (H), World History (H), Essay Writing, Literature, Vocabulary Development"
                Email="LeroyYang"
                TITLE="CO-FOUNDER"
              />
              <Person
                ImageName="VictoriaBaik"
                Name="Victoria Baik"
                Subjects="Pre-Algebra, Algebra 1, Geometry (H), World History (H) (AP), European History (H) (AP), AP Psychology, Ethics and Philosophy, Korean (H)"
                Email="VictoriaBaik"
                TITLE={"SECRETARY"}
              />
              <Person
                ImageName="LuciaJeong"
                Name="Lucia Jeong"
                Subjects="Common Core Math, Pre-Algebra, Algebra 1, Algebra 2 (H), Korean (H)"
                Email="LuciaJeong"
                TITLE={"CONTRIBUTOR"}
              />
              <Person
                ImageName="FelixLerch"
                Name="Felix Lerch"
                Subjects="Algebra 1, Geometry (H), Algebra 2, AP Computer Science Principles, AP Computer Science A, Literature, Essay Writing, Vocabulary Development"
                Email="FelixLerch"
              />
              <Person
                ImageName="ConnorLee"
                Name="Connor Lee"
                Subjects="Pre-Algebra, Algebra 1, Geometry (H), Algebra 2 (H), Pre-Calculus (H) (AP), AP Calculus AB, Chemistry (H), AP Physics 1, AP Computer Science Principles"
                Email="ConnorLee"
                TITLE={"CONTRIBUTOR"}
              />
              <Person
                ImageName="AbbyChiang"
                Name="Abigail Chiang"
                Subjects="Common Core Math, Pre Algebra, Algebra 1, Algebra 2 (H), Chemistry (H) (AP), Chinese (H) (AP), Essay Writing"
                Email="AbigailChiang"
                TITLE={"CONTRIBUTOR"}
              />
              <Person
                ImageName="GiulioButtari"
                Name="Giulio Buttari"
                Subjects="Algebra 1, Geometry (H), Algebra 2, AP Computer Science Principles, AP Computer Science A, Biology (H), Chemistry (H), European History (H) (AP), Spanish (H), Italian (H)"
                Email="GiulioButtari"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/**
 *
 * @param {String, String, String, String} param0
 * @returns
 */
function Person({ ImageName, Name, Subjects, Phone, Email, TITLE }) {
  return (
    <div className="tutors-container">
      <div className="tutor-image-container">
        <img
          src={`/images/Volunteers/${ImageName}.webp`}
          alt="Tutor"
          className="tutor-photos"
        />
      </div>

      <div className="tutor-name-container">
        <div className="tutor-name">
          <b>Name:</b> {Name} <div className="ownerText">{TITLE}</div>
        </div>
        <div className="tutor-name">
          <b>Subjects:</b> {Subjects}
        </div>
        <div className="tutor-name">
          <b>Email:</b> {Email}@limixapp.org
        </div>
      </div>
    </div>
  );
}

export default Directory;
